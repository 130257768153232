import * as React from 'react';

import { Contact, HeroSection, PropertyTaxServices } from '../../components/landing';
import { Footer, GoogleNoIndex, PageTitle } from '../../components/shared';

const LiveIntentPage: React.FunctionComponent = () => {
  return (
    <>
      <PageTitle />
      <GoogleNoIndex />
      <HeroSection leadSource="LiveIntent" />
      <PropertyTaxServices />
      <Contact />
      <Footer />
    </>
  );
};

export default LiveIntentPage;
